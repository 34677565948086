body {
  text-align: center;
  background: #b7cfff;
  font-family: "Arial";
  width: 95%;
  margin: auto auto;
}
button {
  background: #0050f1;
  font-family: "Arial";
  font-weight: bold;
  color: #ffffff;

  border-radius: 20px;
  padding: 10px 20px;
  margin: 20px 10px;
  border: 2px white solid;
}

button:disabled {
  background: #858585;
}

.piano{
  margin: auto;
  width: fit-content;
  border: 5px solid #b7cfff;
  padding: 10px;
}

.notesBody {
  font-family: "Arial";
  text-align: left;
  padding: 20px;
}
.buttonBody {
  font-family: "Arial";
  display: auto;
  flex-direction: row;
  align-items: auto;
  margin: auto;
  padding: 15px;
  background-color: #b7cfff;
  border-radius: 8px;
  width: auto;
  height: auto;
}
.loading {
  width: 120px;
}
img {
  max-width: 100%;
  max-height: 100%;
}

.logo {
  background: #b7cfff;
  object-fit: contain;
  width: 100%;
  height: 100%;
}
h1 {
  font-family: "Arial";
  margin-top: 0px;
}
#audio-player {
  margin-bottom:'50px';
  display: none;
}
#loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 70px;
  height: 70px;
  animation: spin 2s linear infinite;
  margin: auto auto;
  position: relative;
  display: none;
  margin-bottom: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}